import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { Relationship, RequestStatus } from '@entities/relationship';
import { VisoUser, VisoUserRole } from '@entities/viso-user';
import { AssessmentUtilsService } from '@shared/utils/assessment-utils.service';
import { getUserAccount } from '../../../session/redux/session.selectors';
import {
    confirmProceedWithAvailableData,
    getRequestAssessmentEmailsRequest,
    sendAssessmentReminderEmailRequest,
} from '../../redux/actions/assessments.actions';
import { PopulatedAssessment } from '../../models/populated-assessment';
import { OrgAssessmentSummarySections } from '../../../../admin/client-profile/client-profile.model';
import { Artifact } from '@entities/artifact';
import { LongRunningTaskProjection } from '@entities/long-running-tasks';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { FeatureFlags } from '@shared/enums/feature-flags';

@Component({
    selector: 'app-assessment-list',
    templateUrl: './assessment-list.component.html',
    styleUrls: ['./assessment-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'd-block pb-2 px-2',
    },
})
export class AssessmentListComponent implements OnInit {
    @Input({ required: true })
    set latestAssessment(value: PopulatedAssessment) {
        this.latestAssessment$.next(value);
    }

    @Input({ required: true })
    set assessments(value: PopulatedAssessment[]) {
        this.assessments$.next(value);
    }

    @Input({ required: true })
    set relationship(value: Relationship) {
        this._relationship$.next(value);
    }

    @Input({ required: true })
    businessOwner: VisoUser;

    @Input({ required: true })
    isOnlyLegacyAssessmentPresent: boolean;

    @Input({ required: true })
    assessmentSummarySectionConfig: OrgAssessmentSummarySections[];

    @Input({ required: true })
    artifacts: Artifact[];

    @Input({ required: true })
    set isUpForRecertification(value: boolean) {
        this._isUpForRecertification$.next(value);
    }

    @Input({ required: true })
    set isRecertificationOverdue(value: boolean) {
        this._isRecertificationOverdue$.next(value);
    }

    @Input({ required: true })
    orgUserCanOnboardAcceptRisk: boolean;

    @Input({ required: true })
    relationshipLongRunningTasks: LongRunningTaskProjection[];

    @Output()
    startAssessment = new EventEmitter<void>();

    @Output()
    confirmRecertifyAssessment = new EventEmitter<void>();

    @Output()
    cancelAssessment = new EventEmitter<void>();

    @Output()
    editRiskReview = new EventEmitter<void>();

    @Output()
    reviewFollowup = new EventEmitter<void>();

    @Output()
    editFollowup = new EventEmitter<void>();

    get assessmentSummaryConfigLink() {
        if (!this._relationship$.value?.id) {
            return [];
        }

        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this._relationship$.value?.id}/assessment-summary-configuration`,
                },
            },
        ];
    }

    get assessmentSettingsLink() {
        if (!this._relationship$.value?.id) {
            return [];
        }

        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this._relationship$.value?.id}/assessment-settings`,
                },
            },
        ];
    }

    latestAssessment$ = new BehaviorSubject<PopulatedAssessment>(null);
    assessments$ = new BehaviorSubject<PopulatedAssessment[]>([]);
    relationship$: Observable<Relationship>;
    currentAccount$: Observable<VisoUser>;
    isLatestAssessmentCompleted$: Observable<boolean>;
    showStartAnotherAssessmentButton$: Observable<boolean>;
    showRecertificationButton$: Observable<boolean>;
    isAiOnlyAssessmentsEnabled$: Observable<boolean>;

    Roles = VisoUserRole;

    private _relationship$ = new BehaviorSubject<Relationship>(null);
    private _isUpForRecertification$ = new BehaviorSubject<boolean>(false);
    private _isRecertificationOverdue$ = new BehaviorSubject<boolean>(false);

    constructor(
        private _store$: Store,
        private _assessmentUtils: AssessmentUtilsService,
        private _featureFlagService: FeatureFlagService,
    ) {}

    ngOnInit(): void {
        this.relationship$ = this._relationship$.asObservable();

        this.isLatestAssessmentCompleted$ = this.latestAssessment$.pipe(
            map((latestAssessment) => this._assessmentUtils.isAssessmentComplete(latestAssessment?.status)),
        );

        this.currentAccount$ = this._store$.pipe(select(getUserAccount));

        this.isAiOnlyAssessmentsEnabled$ = this._featureFlagService.hasFeatureFlagEnabled(
            FeatureFlags.AI_ONLY_ASSESSMENTS,
        );

        const archivedRelationship$ = this.relationship$.pipe(
            map((relationship) => relationship?.status === RequestStatus.ARCHIVED),
        );

        this.showStartAnotherAssessmentButton$ = combineLatest([
            archivedRelationship$,
            this.isLatestAssessmentCompleted$,
            this._isUpForRecertification$,
        ]).pipe(
            map(
                ([archivedRelationship, isCompleted, isUpForRecertification]) =>
                    !archivedRelationship && isCompleted && !isUpForRecertification,
            ),
        );

        this.showRecertificationButton$ = combineLatest([
            archivedRelationship$,
            this.isLatestAssessmentCompleted$,
            this._isUpForRecertification$,
            this._isRecertificationOverdue$,
        ]).pipe(
            map(
                ([archivedRelationship, isCompleted, isUpForRecertification, isRecertificationOverdue]) =>
                    !archivedRelationship && isCompleted && isUpForRecertification && !isRecertificationOverdue,
            ),
        );
    }

    loadAssessmentData(assessmentId: number): void {
        if (!assessmentId) {
            return;
        }
        this._store$.dispatch(getRequestAssessmentEmailsRequest({ assessmentId }));
    }

    trackByAssessmentId(index: number, assessment: PopulatedAssessment): number {
        return assessment.id;
    }

    sendReminderEmailToUser(assessmentId: number): void {
        this._store$.dispatch(sendAssessmentReminderEmailRequest({ assessmentId }));
    }

    proceedWithAvailableData(assessmentId: number): void {
        this._store$.dispatch(confirmProceedWithAvailableData({ assessmentId }));
    }
}
