<div class="row">
    <div class="col d-flex column-gap-5" data-spotlight-tour="vendor-overview-dynamic-metrics">
        @if (isRelationshipDetails && latestRiskAssessment?.predicted && latestRiskAssessment?.legacy) {
            <div class="row">
                <div class="col">
                    <div class="d-flex">
                        <h1 class="text-normal mb-0" [ngClass]="riskColors(latestRiskAssessment?.inherentRisk)">
                            {{
                                !!latestRiskAssessment?.inherentRisk
                                    ? (latestRiskAssessment?.inherentRisk | riskLevel | async)
                                    : '-'
                            }}
                        </h1>
                        @if (!!latestRiskAssessment?.inherentRiskScore) {
                            <span class="align-content-end ms-2 text-sm text-light">{{
                                roundRiskScore(latestRiskAssessment?.inherentRiskScore)
                            }}</span>
                        }
                    </div>

                    <p>Predicted inherent risk</p>
                </div>
            </div>

            <div class="row">
                <div class="col align-content-center p-0">
                    <mat-icon class="large-icon">chevron_right</mat-icon>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="d-flex">
                        <h1 class="text-normal mb-0" [ngClass]="riskColors(latestRiskAssessment?.risk)">
                            {{
                                !!latestRiskAssessment?.risk && latestRiskAssessment.risk === Risk.NO_CONTEXT
                                    ? 'No Info'
                                    : (latestRiskAssessment.risk | riskLevel | async)
                            }}
                        </h1>
                        @if (!!latestRiskAssessment?.score || latestRiskAssessment?.score === 0) {
                            <span class="align-content-end ms-2 text-sm text-light">{{
                                roundRiskScore(latestRiskAssessment?.score)
                            }}</span>
                        } @else {
                            <mat-icon
                                class="align-content-end ms-2"
                                [inline]="true"
                                color="primary"
                                matTooltip="We're unable to predict residual risk for this vendor due to limited or missing data."
                                >info
                            </mat-icon>
                        }
                    </div>

                    <p>Predicted residual risk</p>
                </div>
            </div>
        }

        @if (vendorDetailsStats?.assessmentsCompleted > 0) {
            <div class="row">
                <div class="col">
                    <h1 class="text-normal mb-0">
                        {{
                            !!vendorDetailsStats?.lastAssessedDate
                                ? (vendorDetailsStats?.lastAssessedDate | timeAgo)
                                : '-'
                        }}
                    </h1>

                    <p>Last assessed</p>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <h1 class="text-normal mb-0">
                        {{
                            !!vendorDetailsStats?.averageResponseTime
                                ? Math.round(vendorDetailsStats.averageResponseTime) +
                                  (Math.round(vendorDetailsStats.averageResponseTime) === 1 ? ' day' : ' days')
                                : '-'
                        }}
                    </h1>

                    <p>Average response time</p>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <h1 class="text-normal mb-0">
                        {{ !!vendorDetailsStats?.assessmentsCompleted ? vendorDetailsStats.assessmentsCompleted : '-' }}
                    </h1>

                    <p>Assessments completed</p>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <mat-divider class="mt-3"></mat-divider>
                </div>
            </div>
        }
    </div>
</div>

@if (isRelationshipDetails) {
    <div class="row">
        <div class="col">
            <div class="row mt-4">
                <div class="col">
                    <span class="mb-2 text-light">Description</span>
                    <p>{{ !!vendorDetails?.description ? vendorDetails?.description : '-' }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex column-gap-5">
                    <div>
                        <span class="text-light">Founded</span>
                        <p>
                            {{ !!vendorDetails?.foundedDate ? (vendorDetails?.foundedDate | date: 'yyyy') : '-' }}
                        </p>
                    </div>
                    <div>
                        <span class="text-light">Location</span>
                        <p>{{ !!vendorAddress ? vendorAddress : '-' }}</p>
                    </div>
                    <div>
                        <span class="text-light">Employees</span>
                        <p>{{ !!companySize ? companySize : '-' }}</p>
                    </div>
                    <div>
                        <div class="text-light">Filing status</div>
                        <p>{{ isPubliclyTraded ? 'Public' : 'Private' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
} @else {
    <div class="row mt-4">
        <div class="col d-flex flex-column">
            <h4 class="text-normal">
                Based on previous assessments, VISO TRUST predicts risk values and aggregates risk-impacting data. Add a
                relationship with {{ vendorDetails?.name }} for an instant analysis.
            </h4>
            <div class="risk-details-prediction-container w-100" data-spotlight-tour="risk-details-prediction">
                <div class="d-flex">
                    <mat-icon class="me-3 text-primary" svgIcon="viso"></mat-icon>
                    @if (vendorDetailsStats?.canPredictRiskUsingExistingInfo) {
                        <h4 class="text-normal">
                            We can estimate the inherent and residual risk for {{ vendorDetails?.name }}
                        </h4>
                    } @else {
                        <h4 class="text-normal">We can estimate the inherent risk for {{ vendorDetails?.name }}</h4>
                    }
                </div>

                @if (vendorDetailsStats?.validatedRiskDomainCount > 0) {
                    <div class="d-flex">
                        <mat-icon fontSet="material-symbols-outlined" class="me-3 text-primary">security</mat-icon>
                        <h4 class="text-normal">
                            We can confirm the assurance of {{ vendorDetailsStats?.validatedRiskDomainCount }}
                            risk
                            {{ vendorDetailsStats?.validatedRiskDomainCount === 1 ? 'dimension' : 'dimensions' }}
                        </h4>
                    </div>
                }
                @if (vendorDetailsStats?.complianceStandardCount > 0) {
                    <div class="d-flex">
                        <mat-icon fontSet="material-symbols-outlined" class="me-3 text-primary"
                            >assured_workload
                        </mat-icon>
                        <h4 class="text-normal">
                            {{ vendorDetails?.name }} likely adheres to
                            {{ vendorDetailsStats?.complianceStandardCount }} compliance
                            {{ vendorDetailsStats?.complianceStandardCount === 1 ? 'standard' : 'standards' }}
                        </h4>
                    </div>
                }
                @if (vendorDetailsStats?.highAssuranceArtifactCount > 0) {
                    <div class="d-flex">
                        <mat-icon fontSet="material-symbols-outlined" class="me-3 text-primary"
                            >sticky_note_2
                        </mat-icon>
                        <h4 class="text-normal">
                            We have evidence of {{ vendorDetailsStats?.highAssuranceArtifactCount }}
                            high-assurance
                            {{ vendorDetailsStats?.highAssuranceArtifactCount === 1 ? 'artifact' : 'artifacts' }}
                        </h4>
                    </div>
                }
                @if (vendorDetailsStats?.doesPredictedContextHaveHighSensitivityData) {
                    <div class="d-flex">
                        <mat-icon fontSet="material-symbols-outlined" class="me-3 text-primary">lock</mat-icon>
                        <h4 class="text-normal">The predicted context includes high-sensitivity data</h4>
                    </div>
                }
            </div>
        </div>
    </div>
}
