<div class="row">
    <div class="col">
        <app-quill-editor-wrapper
            [mentions]="mentions"
            [fastMentions]="fastMentions"
            [editorFormControl]="commentFormControl"
            [styles]="{ height: 'auto', minHeight: '100px' }"
            placeholder="Add a comment"
        ></app-quill-editor-wrapper>
    </div>
</div>
<div class="row">
    <div class="col mt-3">
        <button
            mat-button
            visoButton="primary"
            type="button"
            [disabled]="isFormControlInvalid$ | async"
            (click)="addComment()"
        >
            Submit
        </button>
    </div>
</div>
