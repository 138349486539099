import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    EventEmitter,
    input,
    Input,
    OnChanges,
    Output,
    Signal,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { take, tap } from 'rxjs';
import { Relationship, RequestStatus } from '@entities/relationship';
import { Email } from '@entities/email';
import { VisoUser, VisoUserRole } from '@entities/viso-user';
import { Risk, SlimRiskAssessment } from '@entities/risk-assessment';
import { AssessmentRecommendation, RecommendationType } from '@entities/recommendation';
import { AssessmentUtilsService } from '@shared/utils/assessment-utils.service';
import {
    AssessmentCreatedReasonLabel,
    AssessmentStatus,
    AssessmentStatusLabels,
    AssessmentTypeLabels,
    FollowupType,
    VendorCancelReason,
} from '@entities/assessment';
import { PopulatedAssessment } from '../../models/populated-assessment';
import { AssessmentActions } from '../../redux/actions/assessments.actions';
import { getCurrentEnvironment } from '@shared/utils/environment-utils';
import { Environments } from '../../../../layout/sidebar/menu';
import { OrgAssessmentSummarySections } from '../../../../admin/client-profile/client-profile.model';
import { MatTabGroup } from '@angular/material/tabs';
import { Artifact, ArtifactType } from '@entities/artifact';
import { MatMenu } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { LongRunningTaskName, LongRunningTaskProjection, LongRunningTaskStatus } from '@entities/long-running-tasks';

@Component({
    selector: 'app-assessment-list-item',
    templateUrl: './assessment-list-item.component.html',
    styleUrls: ['./assessment-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentListItemComponent implements AfterViewInit, OnChanges {
    @Input({ required: true })
    relationship: Relationship;

    @Input({ required: true })
    assessment: PopulatedAssessment;

    @Input({ required: true })
    assessmentSummarySectionConfig: OrgAssessmentSummarySections[];

    @Input({ required: true })
    businessOwner: VisoUser;

    @Input({ required: true })
    currentUser: VisoUser;

    @Input()
    defaultExpanded: boolean;

    @Input()
    artifacts: Artifact[];

    @Input()
    orgUserCanOnboardAcceptRisk: boolean;

    relationshipLongRunningTasks = input<LongRunningTaskProjection[]>();

    @Output()
    firstOpen = new EventEmitter<number>();

    @Output()
    startAssessment = new EventEmitter<void>();

    @Output()
    cancelAssessment = new EventEmitter<void>();

    @Output()
    sendReminderEmail = new EventEmitter<void>();

    @Output()
    proceedWithAvailableData = new EventEmitter<void>();

    @Output()
    reviewRisk = new EventEmitter<void>();

    @Output()
    reviewFollowup = new EventEmitter<void>();

    @Output()
    editFollowup = new EventEmitter<void>();

    Roles = VisoUserRole;
    AssessmentActions = AssessmentActions;
    AssessmentCancelledReason = VendorCancelReason;

    artifactTypeNames: { [artifactId: number]: string } = {};
    artifactValidationMessages: { [artifactId: number]: string } = {};
    showAssessmentOptionsButton = false;

    readonly ASSESSMENT_SUMMARY_TAB = 0;
    readonly EMAILS_TAB = 1;

    @ViewChild(MatExpansionPanel)
    private _expansionPanel: MatExpansionPanel;

    @ViewChild('assessmentTabGroup', { static: false })
    private _tabGroup: MatTabGroup;

    @ViewChild('speedDial') _assessmentActionsButton: MatMenu;
    @ViewChild('legacySidenav') legacySidenav: MatSidenav;

    isGenerativeAssessmentProcessing: Signal<boolean>;
    isLegacySidenavOpen = false;

    constructor(private _assessmentUtils: AssessmentUtilsService) {
        this.isGenerativeAssessmentProcessing = computed(() =>
            this.relationshipLongRunningTasks()?.some(
                (task) =>
                    task.name === LongRunningTaskName.LLM_ASSESSMENT_SUMMARY &&
                    task.status === LongRunningTaskStatus.RUNNING,
            ),
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes.artifacts) {
            this.loadArtifactInformation();
        }

        if (!!changes.assessment && !!this._tabGroup) {
            const prevStatus = changes.assessment.previousValue.status as AssessmentStatus;
            const currentStatus = changes.assessment.currentValue.status as AssessmentStatus;
            if (prevStatus != currentStatus && this.isAssessmentComplete) {
                // Jump to Assessment Summary tab if the Client just completed the assessment.
                this._tabGroup.selectedIndex = this.ASSESSMENT_SUMMARY_TAB;
            }
            this.showAssessmentOptionsButton =
                this._assessmentActionsButton._allItems.length &&
                this._assessmentActionsButton._allItems.some((item) => !item.disabled);
        }
    }

    ngAfterViewInit(): void {
        this._expansionPanel.opened
            .pipe(
                tap(() => this.firstOpen.emit(this.assessment?.id)),
                take(1),
            )
            .subscribe();
    }

    get isLatestAssessment(): boolean {
        return this.relationship?.latestAssessmentId === this.assessmentId;
    }

    get isExpanded(): boolean {
        return this._expansionPanel?.expanded;
    }

    get assessmentId(): number {
        return this.assessment?.id;
    }

    get showAuditorView(): boolean {
        // We should always show the assessment details to auditors and support in PROD
        // For lower environments, we can show the details to everyone
        const allowedRoles = [VisoUserRole.Auditor, VisoUserRole.Support];
        return getCurrentEnvironment() !== Environments.PROD
            ? true
            : this.currentUser?.authorities.some((role) => allowedRoles.includes(role));
    }

    get assessmentTypeLabel(): string {
        return !!this.assessment?.type
            ? (this.assessment?.followupType === FollowupType.CONCIERGE
                  ? 'Concierge Assessment'
                  : AssessmentTypeLabels[this.assessment?.type]) +
                  ' - ' +
                  AssessmentCreatedReasonLabel[this.assessment?.createdReason]
            : null;
    }

    get showSendReminderEmailButton(): boolean {
        return (
            !this.assessment?.documentsOnly &&
            this._assessmentUtils.isAssessmentStartedOrCollectingInformation(this.assessment?.status)
        );
    }

    get interactivity(): string {
        let interactivity = this.assessment?.documentsOnly ? 'Non-interactive' : 'Interactive';
        if (this.assessment?.aiProcessingOnly) {
            interactivity += ' | AI-only';
        }
        return interactivity;
    }

    get waitingOnVendor(): boolean {
        return (
            this.isLatestAssessment &&
            this._assessmentUtils.isAssessmentInProgress(this.assessment?.status) &&
            !this.isAssessmentAuditComplete
        );
    }

    get isAssessmentFinished(): boolean {
        return this._assessmentUtils.isAssessmentFinished(this.assessment?.status);
    }

    get isAssessmentCancelled(): boolean {
        return this._assessmentUtils.isAssessmentCancelled(this.assessment?.status);
    }

    get isAssessmentReviewStarted(): boolean {
        return this._assessmentUtils.isAssessmentReviewStarted(this.assessment?.status);
    }

    get isAssessmentAuditComplete(): boolean {
        return this._assessmentUtils.isAssessmentAuditComplete(this.assessment?.status);
    }

    get isAssessmentComplete(): boolean {
        return this._assessmentUtils.isAssessmentComplete(this.assessment?.status);
    }

    get finishedDate(): Date {
        return this.isAssessmentComplete
            ? this.assessment?.auditCompletedDate
            : this.isAssessmentCancelled
              ? this.assessment?.statusHistories?.find(({ status }) => status === AssessmentStatus.CANCELLED)?.date
              : null;
    }

    get assessmentStatus(): string {
        return AssessmentStatusLabels[this.assessment?.status];
    }

    get completedResidualRisk(): Risk {
        return this.isAssessmentComplete ? this.assessment?.completedRiskAssessment?.risk : null;
    }

    get recommendationsCount(): number {
        return this.isAssessmentComplete ? this.recommendations?.length : null;
    }

    get emails(): Email[] {
        return this.assessment?.emails;
    }

    get hasGenerativeAssessmentSummary(): boolean {
        return !!this.assessment?.generativeAssessmentSummary;
    }

    get completedSummary(): string {
        if (!!this.assessment?.generativeAssessmentSummary) {
            return this.assessment?.generativeAssessmentSummary;
        }

        return this.legacySummary;
    }

    get legacySummary(): string {
        const isUserAuditor = this.currentUser?.authorities.some((role) => [VisoUserRole.Auditor].includes(role));
        return isUserAuditor
            ? this.assessment?.completedSummary
            : this._assessmentUtils.getAssessmentSummaryForClientConfiguration(
                  this.assessment?.completedSummary,
                  this.assessmentSummarySectionConfig,
              );
    }

    get completedRiskAssessment(): SlimRiskAssessment {
        return this.assessment?.completedRiskAssessment;
    }

    get recommendations(): AssessmentRecommendation[] {
        return (
            this.assessment?.recommendations?.filter(
                (recommendation) => recommendation.type !== RecommendationType.COMBINED,
            ) || []
        );
    }

    get vendorName(): string {
        return this.relationship?.vendorName;
    }

    get isRelationshipArchived(): boolean {
        return this.relationship?.status === RequestStatus.ARCHIVED;
    }

    get displayAssessmentOutdatedBanner(): boolean {
        const completedRiskAssessmentScore =
            this.assessment?.completedRiskAssessment?.riskScores.SECURITY?.residualRiskScore ??
            this.assessment?.completedRiskAssessment?.score;
        const latestRiskAssessmentScore = this.relationship?.latestRiskAssessment.riskScores.SECURITY.residualRiskScore;
        return this.isLatestAssessment && completedRiskAssessmentScore !== latestRiskAssessmentScore;
    }

    get proceedWithAvailableDataButtonIsDisabled(): boolean {
        return !(this.waitingOnVendor && !this.isAssessmentReviewStarted);
    }

    get showReviewRiskButton(): boolean {
        const canReview = this.isLatestAssessment && this.isAssessmentComplete;
        return this.currentUser?.authorities.includes(VisoUserRole.OrgUser)
            ? canReview && this.orgUserCanOnboardAcceptRisk
            : canReview;
    }

    get showReviewFollowupButton(): boolean {
        return this.isLatestAssessment && this.isAssessmentAuditComplete && !this.isAssessmentComplete;
    }

    get showEditFollowupButton(): boolean {
        return (
            this.isLatestAssessment &&
            this.assessment.followupType !== FollowupType.CONCIERGE &&
            this.waitingOnVendor &&
            !this.assessment.followupRequested
        );
    }

    loadArtifactInformation(): void {
        this.artifacts.forEach((artifact) => {
            this.artifactTypeNames[artifact.id] = artifact.validation?.auditReportType;
            this.artifactValidationMessages[artifact.id] = artifact.validation?.validationMessage;
        });
    }

    hasValidationMessages(): boolean {
        return this.assessment.artifacts.some((artifact) => this.hasValidationMessage(artifact));
    }

    getArtifactsWithValidationMessages(): any[] {
        return this.assessment.artifacts.filter((artifact) => this.hasValidationMessage(artifact));
    }

    private hasValidationMessage(artifact: any): boolean {
        return (
            this.artifactValidationMessages[artifact.id] &&
            this.artifactValidationMessages[artifact.id].trim() !== '' &&
            artifact.type !== ArtifactType.QUESTIONNAIRE_ARTIFACT
        );
    }

    openLegacySidenav(): void {
        this.isLegacySidenavOpen = true;
        if (this.legacySidenav) {
            this.legacySidenav.open();
        }
    }

    closeLegacySidenav(): void {
        this.isLegacySidenavOpen = false;
        if (this.legacySidenav) {
            this.legacySidenav.close();
        }
    }
}
