import { createAction, props } from '@ngrx/store';
import {
    RelationshipAssessmentSettingsResponse,
    UpdateRelationshipAssessmentSettingsRequest,
} from '../../../../entities/relationship/relationship.model';

export enum AssessmentSettingsActions {
    GetRelationshipAssessmentSettingsRequest = '[Request] Get Relationship Assessment Settings Request',
    GetRelationshipAssessmentSettingsRequestSuccess = '[Request] Get Relationship Assessment Settings Request Success',
    GetRelationshipAssessmentSettingsRequestFailed = '[Request] Get Relationship Assessment Settings Request Failed',
    UpdateRelationshipAssessmentSettingsRequest = '[Request] Update Relationship Assessment Settings Request',
    UpdateRelationshipAssessmentSettingsRequestSuccess = '[Request] Update Relationship Assessment Settings Request Success',
    UpdateRelationshipAssessmentSettingsRequestFailed = '[Request] Update Relationship Assessment Settings Request Failed',
}

export const getRelationshipAssessmentSettingsRequest = createAction(
    AssessmentSettingsActions.GetRelationshipAssessmentSettingsRequest,
    props<{ relationshipId: number }>(),
);

export const getRelationshipAssessmentSettingsRequestSuccess = createAction(
    AssessmentSettingsActions.GetRelationshipAssessmentSettingsRequestSuccess,
    props<{ assessmentSettings: RelationshipAssessmentSettingsResponse }>(),
);

export const getRelationshipAssessmentSettingsRequestFailed = createAction(
    AssessmentSettingsActions.GetRelationshipAssessmentSettingsRequestFailed,
);

export const updateRelationshipAssessmentSettingsRequest = createAction(
    AssessmentSettingsActions.UpdateRelationshipAssessmentSettingsRequest,
    props<{
        relationshipId: number;
        updateRelationshipAssessmentSettingsRequest: UpdateRelationshipAssessmentSettingsRequest;
    }>(),
);

export const updateRelationshipAssessmentSettingsRequestSuccess = createAction(
    AssessmentSettingsActions.UpdateRelationshipAssessmentSettingsRequestSuccess,
);

export const updateRelationshipAssessmentSettingsRequestFailed = createAction(
    AssessmentSettingsActions.UpdateRelationshipAssessmentSettingsRequestFailed,
);
