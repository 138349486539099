import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { getProfileAssessmentSummarySections, getUserAuthority } from '../session/redux/session.selectors';
import { OrgAssessmentSummarySections } from '../../admin/client-profile/client-profile.model';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { getCurrentClientProfileRequest, loadClientProfile } from '../session/redux/session.actions';
import {
    generateExampleAssessmentSummaryRequest,
    generateExampleAssessmentSummaryRequestSuccess,
    updateAssessmentSummarySectionConfigRequest,
    updateAssessmentSummarySectionConfigRequestFailed,
    updateAssessmentSummarySectionConfigRequestSuccess,
} from './redux/assessment-summary-management.actions';
import { AssessmentSummarySection } from '../../shared/assessment-components/components/assessment-summary-config/model/assessment-summary-config-models';
import { VisoUserRole } from '../../entities/viso-user';
import { BREADCRUMB_CONTAINER_TOKEN } from '@shared/dynamic-content/dynamic-content-injector';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { FeatureFlagService } from '@shared/services/featureflag.service';

@Component({
    selector: 'app-assessment-summary-management',
    templateUrl: './assessment-summary-management.component.html',
    styleUrls: ['./assessment-summary-management.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentSummaryManagementComponent implements OnInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;

    loadingSummary: boolean = true;

    isCurrentUserOrgAdmin$: Observable<boolean>;
    exampleSummary$ = new BehaviorSubject<string>(null);
    assessmentSummaryConfig$ = new BehaviorSubject<OrgAssessmentSummarySections[]>([]);
    hasPrivacyModuleEnabled$: Observable<boolean>;
    isAiOnlyAssessmentsEnabled$: Observable<boolean>;

    private _unsub$ = new Subject<void>();

    constructor(
        private _cdr: ChangeDetectorRef,
        private _actions$: Actions,
        private _store$: Store,
        private _featureFlagsService: FeatureFlagService,
    ) {}

    ngOnInit(): void {
        this.hasPrivacyModuleEnabled$ = this._featureFlagsService.hasFeatureFlagEnabled(FeatureFlags.PRIVACY_MODULE);
        this.isAiOnlyAssessmentsEnabled$ = this._featureFlagsService.hasFeatureFlagEnabled(
            FeatureFlags.AI_ONLY_ASSESSMENTS,
        );

        this._actions$
            .pipe(
                ofType(
                    updateAssessmentSummarySectionConfigRequestSuccess,
                    updateAssessmentSummarySectionConfigRequestFailed,
                ),
                takeUntil(this._unsub$),
            )
            .subscribe(() => {
                this._store$.dispatch(getCurrentClientProfileRequest());
            });

        this._actions$
            .pipe(
                ofType(loadClientProfile),
                withLatestFrom(this._store$.pipe(select(getProfileAssessmentSummarySections))),
                takeUntil(this._unsub$),
            )
            .subscribe(([_, sections]) => {
                this.assessmentSummaryConfig$.next(sections);
            });

        this.loadAssessmentSummaryConfig();
    }

    assessmentSummaryConfigChanged(payload: Record<AssessmentSummarySection, boolean>) {
        this._store$.dispatch(
            updateAssessmentSummarySectionConfigRequest({
                assessmentSummarySection: { assessmentSummarySectionConfig: payload },
            }),
        );
    }

    private loadAssessmentSummaryConfig() {
        this.loadingSummary = true;
        this._store$.dispatch(generateExampleAssessmentSummaryRequest());

        this.isCurrentUserOrgAdmin$ = this._store$.pipe(
            select(getUserAuthority(VisoUserRole.OrgAdmin)),
            takeUntil(this._unsub$),
        );

        combineLatest([
            this._store$.pipe(select(getProfileAssessmentSummarySections)),
            this._actions$.pipe(ofType(generateExampleAssessmentSummaryRequestSuccess)),
        ])
            .pipe(takeUntil(this._unsub$))
            .subscribe(([assessmentSummarySections, { summary }]) => {
                this.exampleSummary$.next(summary);
                this.assessmentSummaryConfig$.next(assessmentSummarySections);
                this.loadingSummary = false;
                this._cdr.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
