import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getRelationshipState } from '../request.selectors';
import { AppState } from '../request.state';
import { RelationshipAssessmentSettingsResponse } from '@entities/relationship';

const getRelationshipAssessmentSettings: MemoizedSelector<
    AppState,
    {
        loading: boolean;
        saving: boolean;
        data: any;
    }
> = createSelector(getRelationshipState, (state) => state.assessmentSettings);

export const getAssessmentSettingsLoading: MemoizedSelector<AppState, boolean> = createSelector(
    getRelationshipAssessmentSettings,
    (state) => state.loading,
);

export const getAssessmentSettingsSaving: MemoizedSelector<AppState, boolean> = createSelector(
    getRelationshipAssessmentSettings,
    (state) => state.saving,
);

export const getAssessmentSettingsData: MemoizedSelector<AppState, RelationshipAssessmentSettingsResponse> =
    createSelector(getRelationshipAssessmentSettings, (state) => state.data);
