<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">
            Assessment Summary{{ (isAiOnlyAssessmentsEnabled$ | async) ? ' (Legacy)' : '' }}
        </li>
    </ol>
</div>
<div class="content-heading">
    <div>Assessment Summary{{ (isAiOnlyAssessmentsEnabled$ | async) ? ' (Legacy)' : '' }}</div>
</div>
<p *ngIf="isAiOnlyAssessmentsEnabled$ | async">
    This setting applies only to legacy assessment summaries and will not apply to generative summaries for a
    relationship.
</p>
<ng-container *ngIf="loadingSummary; else doneLoading">
    <div class="d-flex align-items-center justify-content-center">
        <div class="sk-spinner sk-spinner-pulse"></div>
    </div>
</ng-container>
<ng-template #doneLoading>
    <app-assessment-summary-config-form
        [hasPrivacyModuleEnabled]="this.hasPrivacyModuleEnabled$ | async"
        [assessmentSummarySections]="assessmentSummaryConfig$ | async"
        [isCurrentUserOrgAdmin]="isCurrentUserOrgAdmin$ | async"
        [exampleSummary]="exampleSummary$ | async"
        (assessmentSummaryConfigChanged)="assessmentSummaryConfigChanged($event)"
    />
</ng-template>
