import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UpdateRelationshipAssessmentSettingsRequest } from '@entities/relationship/relationship.model';
import { filter, pairwise, takeUntil } from 'rxjs/operators';
import {
    getRelationshipAssessmentSettingsRequest,
    updateRelationshipAssessmentSettingsRequest,
} from '../../redux/actions/assessment-settings.actions';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
    getAssessmentSettingsData,
    getAssessmentSettingsLoading,
    getAssessmentSettingsSaving,
} from '../../redux/selectors/assessment-settings.selectors';

export type AssessmentSettingsForm = {
    aiProcessingOnly: FormControl<boolean>;
};

@Component({
    selector: 'app-assessment-settings-dialog',
    templateUrl: './assessment-settings-dialog.component.html',
    styleUrls: ['./assessment-settings-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentSettingsDialogComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    relationshipId: number;

    loading$ = this._store$.select(getAssessmentSettingsLoading);
    saving$ = this._store$.select(getAssessmentSettingsSaving);
    assessmentSettings$ = this._store$.select(getAssessmentSettingsData);
    settingsForm: FormGroup<AssessmentSettingsForm>;

    private _unsub$ = new Subject<void>();

    constructor(
        private _store$: Store,
        public _dialogRef: MatDialogRef<AssessmentSettingsDialogComponent>,
        private _fb: FormBuilder,
        private _cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.settingsForm = this._fb.group({
            aiProcessingOnly: this._fb.control(true),
        });

        this.assessmentSettings$.pipe(takeUntil(this._unsub$)).subscribe((settings) => {
            if (settings) {
                this.settingsForm.controls.aiProcessingOnly.setValue(settings.aiProcessingOnly ?? true);
                this._cdr.markForCheck();
            }
        });

        // Close the dialog when saving is complete and successful
        this.saving$
            .pipe(
                pairwise(),
                filter(([previous, current]) => previous === true && current === false), // Detect when saving changes from true to false
                takeUntil(this._unsub$),
            )
            .subscribe(() => {
                // When saving is complete, check if we have settings data (which means it was successful)
                this.assessmentSettings$
                    .pipe(
                        filter((settings) => !!settings),
                        takeUntil(this._unsub$),
                    )
                    .subscribe(() => {
                        this._dialogRef.close();
                    });
            });

        this.loadAssessmentSettings();
    }

    loadAssessmentSettings(): void {
        this._store$.dispatch(getRelationshipAssessmentSettingsRequest({ relationshipId: this.relationshipId }));
    }

    updateAssessmentSettings(): void {
        const updateRequest: UpdateRelationshipAssessmentSettingsRequest = {
            aiProcessingOnly: this.settingsForm.controls.aiProcessingOnly.value,
        };
        this._store$.dispatch(
            updateRelationshipAssessmentSettingsRequest({
                relationshipId: this.relationshipId,
                updateRelationshipAssessmentSettingsRequest: updateRequest,
            }),
        );
        // Dialog will be closed after saving is complete and successful
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
