import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { AssessmentSettingsDialogComponent } from '../../assessments/assessment-settings-dialog/assessment-settings-dialog.component';
import { MatDialogWrapperService } from '../../../../shared/modal/mat-dialog-wrapper.service';
import { RelationshipService } from '../../../../entities/relationship';
import { SnackbarService } from '../../../../shared/components/snackbar/snackbar.service';
import {
    getRelationshipAssessmentSettingsRequest,
    getRelationshipAssessmentSettingsRequestFailed,
    getRelationshipAssessmentSettingsRequestSuccess,
    updateRelationshipAssessmentSettingsRequest,
    updateRelationshipAssessmentSettingsRequestFailed,
    updateRelationshipAssessmentSettingsRequestSuccess,
} from '../actions/assessment-settings.actions';

@Injectable()
export class AssessmentSettingsEffects {
    getRelationshipAssessmentSettingsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRelationshipAssessmentSettingsRequest),
            switchMap(({ relationshipId }) =>
                this._requestService.findAssessmentSettingsForRelationship(relationshipId).pipe(
                    map((assessmentSettings) =>
                        getRelationshipAssessmentSettingsRequestSuccess({ assessmentSettings }),
                    ),
                    catchError(() => of(getRelationshipAssessmentSettingsRequestFailed())),
                ),
            ),
        ),
    );

    updateRelationshipAssessmentSettingsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateRelationshipAssessmentSettingsRequest),
            switchMap(({ relationshipId, updateRelationshipAssessmentSettingsRequest }) =>
                this._requestService
                    .updateAssessmentSettingsForRelationship(
                        relationshipId,
                        updateRelationshipAssessmentSettingsRequest,
                    )
                    .pipe(
                        map(() => updateRelationshipAssessmentSettingsRequestSuccess()),
                        catchError(() => of(updateRelationshipAssessmentSettingsRequestFailed())),
                    ),
            ),
        ),
    );

    updateRelationshipAssessmentSettingsRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(updateRelationshipAssessmentSettingsRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Assessment settings updated successfully');
                }),
            ),
        { dispatch: false },
    );

    updateRelationshipAssessmentSettingsRequestFailed$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(updateRelationshipAssessmentSettingsRequestFailed),
                tap(() => {
                    this._snackbarService.error('Failed to update assessment settings');
                }),
            ),
        { dispatch: false },
    );

    openAssessmentSettingsModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                filter(
                    (route) =>
                        !!route && route.routeConfig.path === 'relationships/:relationshipId/assessment-settings',
                ),
                map((route) => route.params.relationshipId),
                tap((relationshipId) =>
                    this._dialogService.open(AssessmentSettingsDialogComponent, {
                        inputs: {
                            relationshipId,
                        },
                        config: {
                            maxWidth: 600,
                            minWidth: 600,
                            maxHeight: '90vh',
                        },
                    }),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _dialogService: MatDialogWrapperService,
        private _requestService: RelationshipService,
        private _snackbarService: SnackbarService,
    ) {}
}
