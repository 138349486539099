<h2 mat-dialog-title>Assessment settings</h2>
<mat-dialog-content>
    @defer (when !(loading$ | async)) {
        <div class="mb-3">
            <h4 class="mb-0">Analysis method</h4>
            <p class="text-muted">How do you want to get the assessment results?</p>
            <mat-radio-group [formControl]="settingsForm.controls.aiProcessingOnly" class="d-flex flex-column">
                <mat-radio-button [value]="true" color="primary" class="mb-3">
                    <div class="d-flex flex-column">
                        <strong class="text-sm text-tertiary">Fastest results</strong>
                        <strong>AI only</strong>
                        <p class="mb-0 text-muted">
                            We’ll analyze your assessment using AI and deliver results instantly. You can choose to
                            follow up with the vendor or close the assessment if you're satisfied with the findings.
                        </p>
                    </div>
                </mat-radio-button>
                <mat-radio-button [value]="false" color="primary">
                    <div class="d-flex flex-column">
                        <strong class="text-sm text-tertiary">Highest accuracy</strong>
                        <strong>AI + Expert Auditor</strong>
                        <p class="mb-0 text-muted">
                            We’ll analyze your assessment using AI, followed by a verification from a VISO TRUST expert
                            to ensure accuracy and confidence before finalizing the results.
                        </p>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    } @placeholder {
        <div class="d-flex align-items-center justify-content-center">
            <div class="sk-spinner sk-spinner-pulse"></div>
        </div>
    }
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
    <button mat-button color="primary" type="button" mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" [disabled]="saving$ | async" (click)="updateAssessmentSettings()">
        Save
    </button>
</mat-dialog-actions>
